import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@common/components/';
import OverviewListSearchBar from '@icoach/components/overviewList/OverviewListSearchBar';
import OverviewListTable from '@icoach/components/overviewList/OverviewListTable';
import clsx from 'clsx';
import { OverviewProvider } from '@icoach/components/overviewList/OverviewContext';

const OverviewList = ({
    className,
    children,
    contextValue,
    list,
    headerRow,
    refresh,
    bodyRowClass,
    onChangePage,
    pageIndex,
    totalPage,
    isPagination,
    isMasonryPagination,
    isGray,
    ...rest
}) => {
    return (
        <OverviewProvider value={contextValue}>
            <Box className={clsx('overview-list-container', className)} {...rest}>
                {children}
                <OverviewListTable
                    headerRow={headerRow}
                    list={list}
                    onChangePage={onChangePage}
                    refresh={refresh}
                    bodyRowClass={bodyRowClass}
                    pageIndex={pageIndex}
                    totalPage={totalPage}
                    isPagination={isPagination}
                    isMasonryPagination={isMasonryPagination}
                    isGray={isGray}
                />
            </Box>
        </OverviewProvider>
    );
};

OverviewList.SearchBar = OverviewListSearchBar;

OverviewList.prototype = {
    contextValue: PropTypes.object,
    children: PropTypes.node,
    ...OverviewList.prototype,
};

export default OverviewList;
