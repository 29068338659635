import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import OverviewListTable from '@icoach/components/overviewList/OverviewListTable';
import ProductCard from '@icoach/components/product/ProductCard';

const PurchaseReceiptStorageRecordDialog = ({ className, open, sourceData = {}, onClose, refresh }) => {
    const headerRowData = useMemo(
        () => [
            {
                cellKey: 'productName',
                headerLabel: '商品清單',
                formatCell: (productName, { sku, variantName, productQuantitySummaryRef }) => {
                    if (productQuantitySummaryRef?.current) return <span className={'font-weight-bold'}>{productName}</span>;
                    return <ProductCard productName={productName} sku={sku} spec={variantName} />;
                },
            },
            {
                cellKey: 'quantity',
                headerLabel: '訂購',
                align: 'center',
                formatCell: (value) => {
                    return value;
                },
            },
            {
                cellKey: 'alreadyInventoryQuantity',
                headerLabel: '入庫',
                align: 'center',
                formatCell: (value) => {
                    return <span className={'font-weight-bold text-success'}>{value}</span>;
                },
            },
        ],
        // eslint-disable-next-line
        [],
    );

    const handleSubmit = () => {};

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>進貨單入庫紀錄</GradientColorHeader>
            <DialogContent>
                <Box className={'mb-3'}>
                    <Grid container spacing={2}>
                        <Grid xs={6} item>
                            <ReadTextField label={'入庫時間'}>{parseDate(sourceData?.inspectionDate, DateTimeStandard.DisplayFullBySlash)}</ReadTextField>
                        </Grid>
                        <Grid xs={6} item>
                            <ReadTextField label={'擔當'}>{sourceData?.purchaseReceiptNo}</ReadTextField>
                        </Grid>
                    </Grid>
                </Box>
                <OverviewListTable headerRow={headerRowData} isGray />
            </DialogContent>
        </Dialog>
    );
};

PurchaseReceiptStorageRecordDialog.propTypes = {};

export default PurchaseReceiptStorageRecordDialog;
