import useAjax from '@apis/useAjax';
import { POST_INSPECTION_ORDER_API, POST_INSPECTION_ORDER_REPORT_ISSUE_API } from '@apis/apiPath';
import { useCallback } from 'react';

const useInspectionOrderApi = () => {
    const Ajax = useAjax();

    const postInspectionOrderApi = useCallback(
        async (params) => await Ajax.post(POST_INSPECTION_ORDER_API, params),
        // eslint-disable-next-line
        [],
    );

    const postInspectionOrderReportIssueApi = useCallback(
        async (params = {}) => {
            return await Ajax.post(POST_INSPECTION_ORDER_REPORT_ISSUE_API(params?.inspectionOrderId ?? 0), params);
        },
        // eslint-disable-next-line
        [],
    );
    return {
        postInspectionOrderApi,
        postInspectionOrderReportIssueApi,
    };
};

export default useInspectionOrderApi;
