import React, { useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Chip, MenuItem, SelectField, Stack, TextField, Typography } from '@common/components/';
import { CyclePurchaseProvider } from '@icoach/documents/cyclePurchase/CyclePurchaseContext';
import { DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER, DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH, DOCUMENT_CYCLE_PURCHASE_PATH } from '@icoach/router/routerPath';
import { initBookmark } from '@icoach/members/staticData';
import PageLayout from '@icoach/components/layout/PageLayout';
import OverviewList from '@icoach/components/overviewList/OverviewList';
import useOverview from '@util/hook/useOverview';
import { Grid } from '@mui/material';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import { BusinessCardBox, MoreLabelBox, PageSortAndTotalBox } from '@icoach/components';
import { COLOR_MATCH } from '@icoach/documents/cyclePurchase/staticData';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import CycleOrderListBox from '@icoach/documents/cyclePurchase/components/CycleOrderListBox';
import { CycleOrderDeleteDialog } from '@icoach/documents/dialog';

const DefaultSortBy = 2;

const MainMoreLabel = (props) => {
    const { memberID, cycleOrderID, refresh } = props;
    const moreLabelMap = useRef({
        view: {
            text: '查看',
            to: () => DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID),
        },
        modify: {
            text: '變更申請書',
            to: () => DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH(memberID, cycleOrderID),
        },
        delete: {
            text: '刪除申請書',
            dialogString: 'delete',
            Dialog: CycleOrderDeleteDialog,
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} refresh={refresh} />;
};

const CyclePurchaseOverviewPage = () => {
    const { postCyclePurchaseOverviewApi, getCyclePurchaseOverviewOptionsApi } = useCyclePurchaseApi();
    const headerRow = useMemo(
        () => [
            {
                cellKey: 'cycleOrderNo',
                headerLabel: '循環訂單編號',
                width: '12',
                align: 'center',
                formatCell: (cellValue, { memberID, cycleOrderID, statusColor, cycleOrderTypeText }) => (
                    <Stack direction={'column'}>
                        <Button component={NavLink} to={DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID)}>
                            {cellValue}
                        </Button>
                        <Chip label={cycleOrderTypeText} color={COLOR_MATCH[statusColor]} title={'訂單狀態'} />
                    </Stack>
                ),
            },
            {
                cellKey: 'memberName',
                headerLabel: '會員',
                formatCell: (memberName, { memberNo, nickName, memberImg, membershipStatus }) => {
                    return (
                        <BusinessCardBox
                            sourceData={{
                                memberName,
                                memberNo,
                                nickName,
                                memberImg,
                                membershipStatus,
                            }}
                        />
                    );
                },
            },
            {
                cellKey: 'items',
                headerLabel: '訂單內容',
                formatCell: (value, { shippingDayText }) => {
                    return <CycleOrderListBox orderData={value} shippingDayText={shippingDayText} />;
                },
            },
            {
                cellKey: 'saleAmount',
                headerLabel: '訂單金額',
                align: 'center',
                formatCell: (cellValue) => formatCurrencyFn(cellValue),
            },
            {
                cellKey: 'cardExpiryDate',
                headerLabel: '信用卡效期',
                align: 'center',
                formatCell: (cellValue) => {
                    const cardExpiryDate = cellValue || '0000'.split('');
                    return `${cardExpiryDate[0]}${cardExpiryDate[1]}月/${cardExpiryDate[2]}${cardExpiryDate[3]}年`;
                },
            },
            {
                cellKey: 'updatedDate',
                headerLabel: '更新日期',
                width: '12',
                formatCell: (cellValue) => {
                    return (
                        <React.Fragment>
                            <Typography className="mb-0">{parseDate(cellValue, DateTimeStandard.DisplayDateBySlash)}</Typography>
                            <Typography className="mb-0 font-color-3">{parseDate(cellValue, DateTimeStandard.DisplayTime)}</Typography>
                        </React.Fragment>
                    );
                },
            },
            {
                cellKey: 'employeeName',
                headerLabel: '擔當',
                align: 'center',
            },
            {
                cellKey: 'cycleOrderID',
                headerLabel: '',
                align: 'right',
                isIcon: true,
                formatCell: (_, { canDeleted, canEffection, ...rest }, { refresh }) => {
                    let moreAction = {
                        view: true,
                        modify: canEffection,
                        delete: canDeleted,
                    };
                    let getMoreActionArray = () => {
                        let array = [];
                        for (let key in moreAction) {
                            let bool;
                            if (moreAction.hasOwnProperty(key)) {
                                bool = moreAction[key];
                            }
                            if (bool) {
                                array.push(key);
                            }
                        }
                        return array;
                    };
                    return <MainMoreLabel {...rest} moreActionArray={getMoreActionArray()} refresh={refresh} />;
                },
            },
        ],
        // eslint-disable-next-line
        [],
    );
    const pageActions = useMemo(
        () => [
            {
                label: '新增循環訂購單',
                variant: 'contained',
                to: DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER,
                as: NavLink,
            },
        ],
        // eslint-disable-next-line
        [],
    );

    const { options, overviewData, searchParams, updateQuery, getOverviewApi, handleClearSearch } = useOverview({
        getOptionsApi: getCyclePurchaseOverviewOptionsApi,
        getOverviewApi: postCyclePurchaseOverviewApi,
        defaultQueryParams: {
            ...initBookmark(DefaultSortBy),
            memberSearch: '',
            shippingDay: 99,
            cycleOrderType: 1,
            cycleOrderNo: '',
            productName: '',
            employeeID: 0,
        },
        isMasonryPagination: true,
    });

    const { shippingDayOptions = [], cycleOrderTypeOptions = [], coachOptions: employeeOptions = [], cycleOrderOrderByOptions } = options || {};

    const { pageIndex = 1, memberSearch, shippingDay, cycleOrderType, cycleOrderNo, productName, employeeID, sortBy } = searchParams || {};

    const memberSearchRef = useRef(null);
    const shippingDayRef = useRef(null);
    const cycleOrderTypeRef = useRef(null);
    const cycleOrderNoRef = useRef(null);
    const productNameRef = useRef(null);
    const employeeRef = useRef(null);

    const getParamsData = (sortBy) => {
        let result = {
            ...initBookmark(sortBy),
        };

        if (memberSearchRef.current && memberSearchRef.current.getResult) {
            let value = memberSearchRef.current.getResult();
            value = value.trim();
            Object.assign(result, { memberSearch: value });
        }

        if (shippingDayRef.current && shippingDayRef.current.getResult) {
            Object.assign(result, { shippingDay: shippingDayRef.current.getResult() });
        }

        if (cycleOrderTypeRef.current && cycleOrderTypeRef.current.getResult) {
            Object.assign(result, { cycleOrderType: cycleOrderTypeRef.current.getResult() });
        }

        if (cycleOrderNoRef.current && cycleOrderNoRef.current.getResult) {
            let value = cycleOrderNoRef.current.getResult();
            value = value.trim();
            Object.assign(result, { cycleOrderNo: value });
        }

        if (productNameRef.current && productNameRef.current.getResult) {
            let value = productNameRef.current.getResult();
            value = value.trim();
            Object.assign(result, { productName: value });
        }

        if (employeeRef.current && employeeRef.current.getResult) {
            Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }

        return result;
    };

    const handleSubmit = () => {
        const paramsData = getParamsData(sortBy);
        const isError = refIsRequiredError(memberSearchRef, shippingDayRef, cycleOrderTypeRef, cycleOrderNoRef, productNameRef, employeeRef);

        if (!isError) {
            updateQuery(paramsData);
        }
    };

    const handlePageChange = (sortBy) => (e, page) => {
        const params = getParamsData(sortBy);
        params.pageIndex = page;
        updateQuery(params);
    };

    const handleSortChange = (value) => {
        const params = getParamsData(value);
        updateQuery(params);
    };

    return (
        <CyclePurchaseProvider value={{}}>
            <PageLayout>
                <PageLayout.Header title={'循環訂單總覽'} actions={pageActions} />
                <OverviewList
                    headerRow={headerRow}
                    list={overviewData?.list}
                    totalPage={overviewData?.totalPage}
                    pageIndex={pageIndex}
                    onChangePage={handlePageChange(sortBy)}
                    refresh={getOverviewApi}
                    isPagination
                    isMasonryPagination
                >
                    <OverviewList.SearchBar className={'mb-3'} onSubmit={handleSubmit} onClearSearch={handleClearSearch}>
                        <Grid spacing={2} container>
                            <Grid xs={4} item>
                                <TextField
                                    label={'會員'}
                                    key={memberSearch}
                                    ref={memberSearchRef}
                                    defaultValue={memberSearch}
                                    InputProps={{ placeholder: '請輸入會員姓名或會員編號或手機' }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <SelectField label={'出貨週期'} key={shippingDay} ref={shippingDayRef} defaultValue={shippingDay} fullWidth>
                                    {Array.isArray(shippingDayOptions) &&
                                        shippingDayOptions
                                            .filter((item) => item || !item.disabled)
                                            .map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.text}
                                                </MenuItem>
                                            ))}
                                </SelectField>
                            </Grid>
                            <Grid xs={4} item>
                                <SelectField label={'訂單狀態'} key={cycleOrderType} ref={cycleOrderTypeRef} defaultValue={cycleOrderType} fullWidth>
                                    {Array.isArray(cycleOrderTypeOptions) &&
                                        cycleOrderTypeOptions
                                            .filter((item) => item || !item.disabled)
                                            .map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.text}
                                                </MenuItem>
                                            ))}
                                </SelectField>
                            </Grid>
                            <Grid xs={4} item>
                                <TextField
                                    label={'訂單編號'}
                                    key={cycleOrderNo}
                                    ref={cycleOrderNoRef}
                                    defaultValue={cycleOrderNo}
                                    InputProps={{ placeholder: '請輸入訂單編號' }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <TextField
                                    label={'商品名稱'}
                                    key={productName}
                                    ref={productNameRef}
                                    defaultValue={productName}
                                    InputProps={{ placeholder: '請輸入商品名稱' }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <SelectField label={'擔當'} key={employeeID} ref={employeeRef} defaultValue={employeeID} fullWidth displayEmpty>
                                    {Array.isArray(employeeOptions) &&
                                        employeeOptions
                                            .filter((item) => item || !item.disabled)
                                            .map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.text}
                                                </MenuItem>
                                            ))}
                                </SelectField>
                            </Grid>
                        </Grid>
                    </OverviewList.SearchBar>
                    <Stack className={'mb-1'} alignItems={'center'} justifyContent={'end'}>
                        <PageSortAndTotalBox
                            className={'mb-1'}
                            pageIndex={pageIndex}
                            pageSize={overviewData?.pageSize}
                            totalCount={overviewData?.totalCount}
                            sortDefaultValue={sortBy}
                            sortOptions={cycleOrderOrderByOptions}
                            sortOnChange={handleSortChange}
                            isCurrentPageLastIndex
                            isSort
                        />
                    </Stack>
                </OverviewList>
            </PageLayout>
        </CyclePurchaseProvider>
    );
};

export default CyclePurchaseOverviewPage;
