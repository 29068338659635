import React, { memo, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, Step, Stepper, StepLabel, Typography, MenuItem, SelectField, Box, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import OverviewListTable from '@icoach/components/overviewList/OverviewListTable';
import ProductCard from '@icoach/components/product/ProductCard';
import PurchaseInspectionQuantityInput from '@icoach/purchaseReceipt/PurchaseInspectionQuantityInput';
import useTabState from '@util/hook/useTabState';
import useGetEmployeeOptionApi from '@util/hook/useGetEmployeeOptionApi';
import { refIsRequiredError } from '@util/utils';
import { MagnifyingGlassSolid as MagnifyingGlassSolidIcon } from '../../components/icons/SvgIcon/';
import useInspectionOrderApi from '@apis/useInspectionOrderApi';

const Step1Content = memo(
    ({ headerRow, list, listRef }) => {
        return (
            <>
                <TextField
                    className={'purchase-inspection-dialog__search-bar'}
                    InputProps={{ placeholder: '輸入商品名稱或sku', startAdornment: <MagnifyingGlassSolidIcon /> }}
                    fullWidth
                />
                <OverviewListTable headerRow={headerRow} list={list} tableRowBodyProps={{ listRef: listRef }} isGray />
            </>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.list === nextProps.list;
    },
);

const Step2Content = ({ headerRow, list, listRef, employeesRef, productQuantitySummaryRef, handleProductQuantitySummary, option }) => {
    productQuantitySummaryRef.current = {
        purchaseQuantityTotal: 0,
        inspectionQuantityTotal: 0,
        inspectingQuantityTotal: 0,
        missingQuantityTotal: 0,
    };
    return (
        <>
            <OverviewListTable
                headerRow={headerRow}
                list={list}
                tableRowBodyProps={{
                    listRef: listRef,
                    productQuantitySummaryRef: productQuantitySummaryRef,
                    handleProductQuantitySummary: handleProductQuantitySummary,
                }}
                bodyRowClass={{
                    key: 'productName',
                    value: '總計',
                    className: 'product-quantity-summary-row',
                }}
                isGray
            />
            <Typography className={'mt-2'}>
                入庫後，庫存量會增加，請確定是否入庫。
                <br />
                如發生到貨數量不齊全，「確認入庫」後系統會發「到貨異常」通知，專員將盡快為您處理。
            </Typography>
            <SelectField label="入庫擔當" ref={employeesRef} required fullWidth>
                {option.map(({ text, value }) => (
                    <MenuItem value={value} key={value}>
                        {text}
                    </MenuItem>
                ))}
            </SelectField>
        </>
    );
};

const PurchaseInspectionDialog = ({ className, open, sourceData, refresh, onClose }) => {
    const { items = [], purchaseReceiptID } = sourceData || {};
    const { option } = useGetEmployeeOptionApi();
    const { postInspectionOrderApi } = useInspectionOrderApi();

    const { activeTab, handleTabChange } = useTabState(0);
    const isStep1Visible = activeTab === 0;
    const isStep2Visible = activeTab === 1;

    const headerRowData = useMemo(
        () => {
            const getInspectionQuantity = (target) => {
                const { inspectionQuantity } = target.getResult() || 0;
                return parseInt(inspectionQuantity);
            };
            return {
                receivedItemsList: [
                    {
                        cellKey: 'productName',
                        headerLabel: '商品清單',
                        formatCell: (productName, { sku, variantName }) => {
                            if (sku) {
                                return <ProductCard productName={productName} sku={sku} spec={variantName} />;
                            } else {
                                return <span className={'font-weight-bold'}>總計</span>;
                            }
                        },
                    },
                    {
                        cellKey: 'variantID',
                        align: 'right',
                        formatCell: (variantID, { shouldInspectionQuantity }, { listRef }) => {
                            return (
                                <PurchaseInspectionQuantityInput
                                    ref={(ref) => (listRef.current[variantID] = ref)}
                                    purchaseQuantity={shouldInspectionQuantity}
                                    variantID={variantID}
                                />
                            );
                        },
                    },
                ],
                inventoryCheckedItemsList: [
                    {
                        cellKey: 'productName',
                        headerLabel: '商品清單',
                        formatCell: (productName, { sku, variantName, productQuantitySummaryRef }) => {
                            if (productQuantitySummaryRef?.current) return <span className={'font-weight-bold'}>{productName}</span>;
                            return <ProductCard productName={productName} sku={sku} spec={variantName} />;
                        },
                    },
                    {
                        cellKey: 'quantity',
                        headerLabel: '訂購',
                        align: 'center',
                        formatCell: (quantity, { productQuantitySummaryRef }) => {
                            if (productQuantitySummaryRef?.current) return productQuantitySummaryRef.current.purchaseQuantityTotal;
                            return quantity;
                        },
                    },
                    {
                        cellKey: 'inspectingQuantityTotal',
                        headerLabel: '點收',
                        align: 'center',
                        formatCell: (_, { variantID, productName, productQuantitySummaryRef }, { listRef }) => {
                            if (productQuantitySummaryRef?.current)
                                return <span className={'text-success'}>{productQuantitySummaryRef.current.inspectingQuantityTotal}</span>;
                            if (listRef.current[variantID]) {
                                const quantity = getInspectionQuantity(listRef.current[variantID]);
                                return <span className={'font-weight-bold text-success'}>{quantity}</span>;
                            } else {
                                return '';
                            }
                        },
                    },
                    {
                        cellKey: 'missingQuantity',
                        headerLabel: '未到貨',
                        align: 'center',
                        formatCell: (
                            value,
                            { productName, quantity, shouldInspectionQuantity, variantID, inspectionQuantity, productQuantitySummaryRef },
                            { listRef, handleProductQuantitySummary },
                        ) => {
                            if (productQuantitySummaryRef?.current)
                                return <span className={'text-error'}>{productQuantitySummaryRef.current.missingQuantityTotal}</span>;
                            if (listRef.current[variantID]) {
                                const inspectingQuantity = parseInt(getInspectionQuantity(listRef.current[variantID]));
                                const missingQuantity = parseInt(shouldInspectionQuantity) - inspectingQuantity;

                                handleProductQuantitySummary({
                                    quantity,
                                    inspectingQuantity,
                                    inspectionQuantity,
                                    missingQuantity,
                                });

                                if (missingQuantity > 0) return <span className={'text-error'}>{missingQuantity}</span>;
                                return missingQuantity;
                            } else {
                                return value;
                            }
                        },
                    },
                    {
                        cellKey: 'inspectionQuantity',
                        headerLabel: '已入庫',
                        align: 'center',
                        formatCell: (inspectionQuantity, { productQuantitySummaryRef }) => {
                            if (productQuantitySummaryRef?.current) return productQuantitySummaryRef.current.inspectionQuantityTotal;
                            return inspectionQuantity;
                        },
                    },
                    {
                        cellKey: 'inventoryQuantity',
                        headerLabel: '庫存',
                        align: 'center',
                        formatCell: (inventoryQuantity, { variantID }, { listRef }) => {
                            if (typeof inventoryQuantity === 'number') {
                                const { inspectionQuantity } = listRef.current[variantID] ? listRef.current[variantID]?.getResult() : 0;
                                return (
                                    <span className={'font-weight-bold'}>
                                        {inventoryQuantity} <span className={'text-success'}>+ {inspectionQuantity}</span>
                                    </span>
                                );
                            } else {
                                return '';
                            }
                        },
                    },
                ],
            };
        },
        // eslint-disable-next-line
        [],
    );

    const stepperData = useMemo(
        () => ['到貨點收', '確認入庫'],
        // eslint-disable-next-line
        [],
    );

    const listRef = useRef({});
    const employeesRef = useRef(null);

    const productQuantitySummaryRef = useRef({});
    const handleProductQuantitySummary =
        (productQuantitySummaryRef) =>
        (values = {}) => {
            let {
                purchaseQuantityTotal = 0,
                inspectionQuantityTotal = 0,
                inspectingQuantityTotal = 0,
                missingQuantityTotal = 0,
            } = productQuantitySummaryRef.current;

            const toSafeNumber = (value) => {
                const num = parseInt(value);
                return isNaN(num) ? 0 : num;
            };

            purchaseQuantityTotal += toSafeNumber(values?.quantity);
            inspectionQuantityTotal += toSafeNumber(values?.inspectionQuantity);
            inspectingQuantityTotal += toSafeNumber(values?.inspectingQuantity);
            missingQuantityTotal += toSafeNumber(values?.missingQuantity);

            productQuantitySummaryRef.current = {
                purchaseQuantityTotal: Object.is(purchaseQuantityTotal, -0) ? 0 : purchaseQuantityTotal,
                inspectionQuantityTotal: Object.is(inspectionQuantityTotal, -0) ? 0 : inspectionQuantityTotal,
                inspectingQuantityTotal: Object.is(inspectingQuantityTotal, -0) ? 0 : inspectingQuantityTotal,
                missingQuantityTotal: Object.is(missingQuantityTotal, -0) ? 0 : missingQuantityTotal,
            };
        };

    const filterNonZeroItems = (items = [], key) => {
        if (!Array.isArray(items) || !key) return [];
        return items.filter((item) => Number(item[key]) > 0);
    };
    const filteredItems = useMemo(() => filterNonZeroItems(items, 'shouldInspectionQuantity'), [items]);
    const filteredItemsWithTotal = useMemo(
        () => [
            ...filteredItems,
            {
                productName: '總計',
                productQuantitySummaryRef,
            },
        ],
        [filteredItems],
    );

    const doInspectionOrderApi = async (params) => {
        const response = await postInspectionOrderApi(params);
        if (response) {
            if (typeof refresh === 'function') refresh();
            onClose();
        }
    };

    const getParams = () => {
        const result = { purchaseReceiptID };

        if (listRef.current) {
            let items = Object.keys(listRef.current).map((key) => {
                const result = listRef.current[key].getResult();
                return result || {};
            });

            result.items = filterNonZeroItems(items, 'inspectionQuantity');
        }

        if (employeesRef.current && employeesRef.current.getResult) {
            result.employeeID = employeesRef.current.getResult();
        }

        return result;
    };

    const handlePreviousStep = (e) => {
        handleTabChange(e, 0);
    };

    const handleNextStep = (e) => {
        const isError = refIsRequiredError(listRef);
        if (!isError) {
            handleTabChange(e, 1);
        }
    };

    const handleSubmit = (e) => {
        if (!refIsRequiredError(employeesRef, listRef)) {
            const params = getParams();
            doInspectionOrderApi(params);
        }
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', 'purchase-inspection-dialog', className)}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>進行商品點收</GradientColorHeader>
            <DialogContent>
                <Grid className={'mb-2'} container spacing={2}>
                    <Grid xs={3} item />
                    <Grid xs={6} item>
                        <Stepper activeStep={activeTab} alternativeLabel>
                            {stepperData.map((label) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid xs={3} item />
                </Grid>
                <Box style={{ display: isStep1Visible ? 'block' : 'none' }}>
                    <Step1Content headerRow={headerRowData.receivedItemsList} list={filteredItems} listRef={listRef} />
                </Box>
                <Box style={{ display: isStep2Visible ? 'block' : 'none' }}>
                    <Step2Content
                        headerRow={headerRowData.inventoryCheckedItemsList}
                        list={filteredItemsWithTotal}
                        option={option}
                        listRef={listRef}
                        employeesRef={employeesRef}
                        productQuantitySummaryRef={productQuantitySummaryRef}
                        handleProductQuantitySummary={handleProductQuantitySummary(productQuantitySummaryRef)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {isStep1Visible && (
                    <Button variant="contained" onClick={handleNextStep}>
                        下一步
                    </Button>
                )}
                {isStep2Visible && (
                    <>
                        <Button variant="outlined" onClick={handlePreviousStep}>
                            上一步
                        </Button>
                        <Button variant="contained" type="submit">
                            確認入庫
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default PurchaseInspectionDialog;
