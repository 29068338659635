import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Typography, Button, Box, Form } from '@common/components/';
import { FeeSection, RenterSection, RenterSectionByRead, RentMainSection, TermSection, TopSection } from '@icoach/rentCabinet/components/DocumentComponents';
import useCabinetRentalApi from '@apis/useCabinetRentalApi';
import { refIsRequiredError, isEmpty } from '@util/utils';
import { RENT_CABINET_DOCUMENT_URL } from '@icoach/router/MembersRouter';
import { cabinetPaymentCycleMap, parseQueryStringToObject } from '@icoach/rentCabinet/staticData';
import terms from '@icoach/rentCabinet/terms';
import moment from 'moment';
import useValidationErrors from '@util/hook/useValidationErrors';

const RentCabinetDocumentPage = (props) => {
    const { rentCabinetID } = props.match.params;
    const search = props.location.search;
    const history = useHistory();
    const { getInitRentCabinetApi, getInitRentCabinetByEditApi, getCheckDebitPersonalApi, postRentCabinetApi, putRentCabinetApi } = useCabinetRentalApi();
    const [membershipData, setMembershipData] = useState();
    const [cabinetData, setCabinetData] = useState();
    const [cabinetFee, setCabinetFee] = useState(0);
    const [paymentCycle, setPaymentCycle] = useState(cabinetPaymentCycleMap.monthly);
    const [rentCabinetData, setRentCabinetData] = useState();
    const { validationErrors, updateValidationErrors, clearValidationErrors } = useValidationErrors();
    const [resource, setResource] = useState({});
    const { cabinets } = resource;
    const topRef = useRef();
    const renterRef = useRef();
    const rentMainRef = useRef();
    const feeRef = useRef();

    // 取得建立初始
    const doInitRentCabinetApi = async () => {
        let res = await getInitRentCabinetApi();
        if (res) {
            const { cabinets, resource: options } = res;
            setResource({ cabinets, ...options });
        }
    };
    // 取得編輯初始
    const doInitRentCabinetByEditApi = async (rentCabinetID, renewCabinetRentalID) => {
        const _id = rentCabinetID || renewCabinetRentalID;
        let res = await getInitRentCabinetByEditApi(_id);
        if (res) {
            let cabinets;
            const { resource: options, rentCabinetData } = res;
            if (!isEmpty(rentCabinetData)) {
                const { cabinetID, cabinetNo, cabinetName, monthlyFee, memo, endDate, paymentCycle } = rentCabinetData;
                const _rentCabinetData = { ...rentCabinetData };
                cabinets = rentCabinetData.resource.cabinetList;

                // 續約
                if (renewCabinetRentalID) {
                    _rentCabinetData.cabinetRentalID = 0;
                    _rentCabinetData.startDate = moment(endDate).add('1', 'days');
                    if (String(paymentCycle) === cabinetPaymentCycleMap.payOff) {
                        _rentCabinetData.endDate = moment(endDate).add('1', 'years');
                    }
                }

                setResource({ cabinets, ...options });
                setPaymentCycle(paymentCycle);
                setCabinetData({ cabinetID, cabinetNo, cabinetName, cabinetFee: monthlyFee, memo });
                setRentCabinetData(_rentCabinetData);
            } else {
                setResource({ ...options });
            }
        }
    };
    // 取得會員會籍資訊
    const doCheckDebitPersonalApi = async (memberID) => {
        let res = await getCheckDebitPersonalApi(memberID);
        if (res) {
            setMembershipData(res);
        }
    };
    // 新增置物櫃租約
    const doPostRentCabinetApi = async (params) => {
        let resp = await postRentCabinetApi(params);
        const { isError = false, result } = resp || {};
        if (isError) {
            // 0002 錯誤
            updateValidationErrors(result);
        } else if (isEmpty(resp)) {
            // 失敗
        } else {
            // 成功
            history.push(RENT_CABINET_DOCUMENT_URL);
        }
    };
    // 更新置物櫃租約
    const doPutRentCabinetApi = async (cabinetRentalID, params) => {
        let res = await putRentCabinetApi(cabinetRentalID, params);
        if (res) {
            history.push(RENT_CABINET_DOCUMENT_URL);
        }
    };

    const getParams = (params) => {
        let result = {};
        if (!isEmpty(params)) {
            Object.assign(result, params);
        }
        if (!isEmpty(rentCabinetData)) {
            const { endDate } = rentCabinetData;
            Object.assign(result, { endDate });
        }
        if (topRef.current && topRef.current.getResult) {
            Object.assign(result, topRef.current.getResult());
        }
        if (renterRef.current && renterRef.current.getResult) {
            Object.assign(result, renterRef.current.getResult());
        }
        if (rentMainRef.current && rentMainRef.current.getResult) {
            Object.assign(result, rentMainRef.current.getResult());
        }
        if (feeRef.current && feeRef.current.getResult) {
            Object.assign(result, feeRef.current.getResult());
        }
        return result;
    };

    const handleSubmit = () => {
        clearValidationErrors();
        let isError = refIsRequiredError(topRef, renterRef, rentMainRef, feeRef);
        if (!isError) {
            let { renewCabinetRentalID = 0 } = parseQueryStringToObject(search);
            let params = getParams({ renewCabinetRentalID });
            if (rentCabinetID) {
                doPutRentCabinetApi(rentCabinetID, params);
            } else {
                doPostRentCabinetApi(params);
            }
        }
    };

    const getCabinetData = (cabinetID) => {
        return cabinets.find((item) => {
            return item.cabinetID === cabinetID;
        });
    };

    const handlePaymentCycleChange = (e, node, value) => {
        setPaymentCycle(value);
    };

    useEffect(() => {
        let { renewCabinetRentalID = 0 } = parseQueryStringToObject(search);
        console.log(renewCabinetRentalID, 'renewCabinetRentalID');
        if (rentCabinetID || renewCabinetRentalID) {
            doInitRentCabinetByEditApi(rentCabinetID, renewCabinetRentalID);
        } else {
            doInitRentCabinetApi();
        }
        // eslint-disable-next-line
    }, [rentCabinetID]);

    useEffect(() => {
        if (search) {
            let { cabinetID, memberID } = parseQueryStringToObject(search);
            if (cabinetID && cabinets) {
                setCabinetData(getCabinetData(parseInt(cabinetID)));
            }
            if (memberID) {
                setRentCabinetData((prev) => ({
                    ...prev,
                    memberID: parseInt(memberID),
                }));
            }
        }
        // eslint-disable-next-line
    }, [cabinets]);

    return (
        <Box className="container main-container-spacing rent-cabinet-page">
            <Form onSubmit={handleSubmit}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        {rentCabinetID ? '編輯置物櫃承租申請書' : '新增置物櫃承租申請書'}
                    </Typography>
                </Box>
                <Card className={'order-info-card mb-4'}>
                    {rentCabinetID ? (
                        <RenterSectionByRead data={rentCabinetData} />
                    ) : (
                        <RenterSection ref={renterRef} data={rentCabinetData} doCheckDebitPersonalApi={doCheckDebitPersonalApi} />
                    )}
                    <RentMainSection
                        ref={rentMainRef}
                        data={{
                            ...rentCabinetData,
                            paymentCycle,
                        }}
                        membershipData={membershipData}
                        cabinetData={cabinetData}
                        resource={resource}
                        isCreate={Boolean(!rentCabinetID)}
                        setCabinetData={setCabinetData}
                        handlePaymentCycleChange={handlePaymentCycleChange}
                        setCabinetFee={setCabinetFee}
                    />
                    {!rentCabinetID && (
                        <FeeSection ref={feeRef} cabinetData={cabinetData} cabinetFee={cabinetFee} paymentCycle={paymentCycle} errorModule={validationErrors} />
                    )}
                </Card>
                <TermSection className={'mb-4'} data={terms} />
                <TopSection ref={topRef} className={'mb-4'} resource={resource} isCreate={Boolean(!rentCabinetID)} />
                <Box className="text-right mt-4">
                    <Button variant="contained" type={'submit'}>
                        確認送出
                    </Button>
                </Box>
            </Form>
        </Box>
    );
};

export default RentCabinetDocumentPage;
